export const mothList = [
  { name: "Январь", value: 1 },
  { name: "Февраль", value: 2 },
  { name: "Март", value: 3 },
  { name: "Апрель", value: 4 },
  { name: "Май", value: 5 },
  { name: "Июнь", value: 6 },
  { name: "Июль", value: 7 },
  { name: "Август", value: 8 },
  { name: "Сентябрь", value: 9 },
  { name: "Октябрь", value: 10 },
  { name: "Ноябрь", value: 11 },
  { name: "Декабрь", value: 12 },
];

export function generateYearArray(count) {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = 0; i < count; i++) {
    years.push(currentYear + i);
  }

  return years;
}

export function createDateFromMonthAndYear(month, year) {
  if (year === undefined) {
    year = new Date().getFullYear();
  }

  if (month === undefined) {
    month = 1;
  }

  return new Date(year, month - 1, 1);
}

export const generateTimePeriods = type => {
  //type: 0 = yesterday-today , 1 = current week-today, 2 = current month-today, 3 = current year-today

  const startDate = new Date();
  const endDate = new Date();
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);

  if (type === 0) {
    startDate.setDate(endDate.getDate() - 1);
  } else if (type === 1) {
    startDate.setDate(endDate.getDate() - ((endDate.getDay() + 6) % 7));
  } else if (type === 2) {
    startDate.setDate(1);
  } else if (type === 3) {
    startDate.setMonth(0, 1);
  }

  return { startDate, endDate };
};

export const toDate = (date, showTime, timezone) => {
  if (Date.parse(date)) {
    if (showTime) {
      if (timezone) {
        return new Intl.DateTimeFormat("ru-Ru", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: timezone,
        }).format(new Date(date));
      } else {
        return new Intl.DateTimeFormat("ru-Ru", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }).format(new Date(date));
      }
    } else {
      return new Intl.DateTimeFormat("ru-Ru", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).format(new Date(date));
    }
  }
};

export const toDateLong = (date, showTime, timezone) => {
  if (Date.parse(date)) {
    if (showTime) {
      if (timezone) {
        return new Intl.DateTimeFormat("ru-Ru", {
          day: "2-digit",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: timezone,
        }).format(new Date(date));
      } else {
        return new Intl.DateTimeFormat("ru-Ru", {
          day: "2-digit",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }).format(new Date(date));
      }
    } else {
      return new Intl.DateTimeFormat("ru-Ru", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      }).format(new Date(date));
    }
  }
};

export const isDateValid = dateString => {
  const date = new Date(dateString);
  return typeof dateString === "object" && !isNaN(date.getTime());
};

export const setStartDateHoursHandler = date => {
  return new Date(new Date(date).setHours(0, 0, 0, 0));
};

export const setEndDateHoursHandler = date => {
  if (date) {
    return new Date(new Date(date).setHours(23, 59, 59, 99));
  } else {
    return date;
  }
};

export const getTomorrowDate = () =>
  new Date(new Date().setDate(new Date().getDate() + 1));

export const getNextWeekDate = () =>
  new Date(new Date().setDate(new Date().getDate() + 6));

export const getNextMonthDate = () =>
  new Date(new Date().setMonth(new Date().getMonth() + 1));

export const getNextYearDate = () =>
  new Date(new Date().setFullYear(new Date().getFullYear() + 1));

function formatMinutesToHours(minutes) {
  const numericValue = parseFloat(minutes);

  const hours = Math.floor(numericValue / 60);
  const remainingMinutes = numericValue % 60;

  return `${String(hours).padStart(2, "0")}:${String(remainingMinutes).padStart(
    2,
    "0",
  )}`;
}

export const createDateStringWithTimezone = date => {
  const currentUserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const dateWithUserTimeZone = new Date(
    date.toLocaleString("en-US", { timeZone: currentUserTimeZone }),
  );

  const timeZoneOffset = new Date().getTimezoneOffset();

  return dateWithUserTimeZone
    .toISOString()
    .replace(
      "Z",
      timeZoneOffset < 0
        ? `+${formatMinutesToHours(String(Math.abs(timeZoneOffset)))}`
        : `-${formatMinutesToHours(String(timeZoneOffset))}`,
    );
};
