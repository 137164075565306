export const EnumPermissionsTypes = {
  view: 0,
  create: 1,
  update: 2,
  delete: 3,
  actions: 4,
  special: 5,
  all: 6,
  import: 7,
};

export const EnumPermissionsParents = {
  geo: "geo",
  dictionaries: "dictionaries",
  permissions: "permissions",
  billing: "billing",
  support: "support",
};

export const EnumPermissionsKeys = {
  healthAssessment: "healthAssessment",
  clientBonuses: "clientBonuses",
  specialistBalance: "specialistBalance",
  billing: "billing",
  doctorBilling: "doctorBilling",
  specialistBilling: "specialistBilling",
  additionalBilling: "additionalBilling",
  lottery: "lottery",
  regionGrids: "regionGrids",
  specialists: "specialists",
  applications: "applications",
  insurance: "insurance",
  insuranceProgram: "insuranceProgram",
  insuranceProgramPayments: "insuranceProgramPayments",
  users: "users",
  orders: "orders",
  referrals: "referrals",
  consultations: "consultations",
  onlineConsultations: "onlineConsultations",
  offlineConsultations: "offlineConsultations",
  reports: "reports",
  countries: "countries",
  cities: "cities",
  clinics: "clinics",
  regions: "regions",
  punkts: "punkts",
  system: "system",
  countryParams: "countryParams",
  services: "services",
  prices: "prices",
  subscribes: "subscribes",
  reviews: "reviews",
  acts: "acts",
  subscribesPrograms: "subscribesPrograms",
  campaigns: "campaigns",
  specializations: "specializations",
  symptoms: "symptoms",
  procedures: "procedures",
  mappings: "mappings",
  content: "content",
  laboratories: "laboratories",
  faq: "faq",
  permissions: "permissions",
  claims: "claims",
  socialStatuses: "socialStatuses",
  contentStatistics: "contentStatistics",
  education: "education",
  contentForms: "contentForms",
  vacancies: "vacancies",
  support: "support",
};

export const ALL_CLAIMS = {
  name: "Полный доступ",
  key: "all_permissions",
};

export const PagePermissions = [
  ALL_CLAIMS,
  {
    name: "Оценка здоровья (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.healthAssessment,
    parent: null,
  },
  {
    name: "Поддержка (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.support,
    parent: EnumPermissionsParents.support,
  },
  {
    name: "Поддержка (действия)",
    type: EnumPermissionsTypes.actions,
    key: EnumPermissionsKeys.support,
    parent: EnumPermissionsParents.support,
  },
  {
    name: "Бонусы клиента (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.clientBonuses,
    parent: null,
  },
  {
    name: "Бонусы клиента (действия)",
    type: EnumPermissionsTypes.actions,
    key: EnumPermissionsKeys.clientBonuses,
    parent: null,
  },
  {
    name: "Баланс специалиста (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.specialistBalance,
    parent: null,
  },
  {
    name: "Баланс специалиста (действия)",
    type: EnumPermissionsTypes.actions,
    key: EnumPermissionsKeys.specialistBalance,
    parent: null,
  },
  {
    name: "Акты (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.acts,
    parent: null,
  },
  {
    name: "Акты (действия)",
    type: EnumPermissionsTypes.actions,
    key: EnumPermissionsKeys.acts,
    parent: null,
  },

  {
    name: "Платежи (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.billing,
    parent: EnumPermissionsParents.billing,
  },
  {
    name: "Платежи (действия)",
    type: EnumPermissionsTypes.actions,
    key: EnumPermissionsKeys.billing,
    parent: null,
  },

  {
    name: "Начисления (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.doctorBilling,
    parent: EnumPermissionsParents.billing,
  },
  {
    name: "Начисления (загрузка файлов)",
    type: EnumPermissionsTypes.special,
    key: EnumPermissionsKeys.doctorBilling,
    parent: null,
  },

  {
    name: "Начисления спец. (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.specialistBilling,
    parent: EnumPermissionsParents.billing,
  },
  {
    name: "Начисления спец. (действия)",
    type: EnumPermissionsTypes.actions,
    key: EnumPermissionsKeys.specialistBilling,
    parent: EnumPermissionsParents.billing,
  },

  {
    name: "Доначисления",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.additionalBilling,
    parent: EnumPermissionsParents.billing,
  },

  {
    name: "Сетки регионов (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.regionGrids,
    parent: EnumPermissionsParents.billing,
  },
  {
    name: "Сетки регионов (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.regionGrids,
    parent: null,
  },
  {
    name: "Сетки регионов (редактирование)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.regionGrids,
    parent: null,
  },
  {
    name: "Сетки регионов (удаление)",
    type: EnumPermissionsTypes.delete,
    key: EnumPermissionsKeys.regionGrids,
    parent: null,
  },

  {
    name: "Заявки на врача (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.applications,
    parent: null,
  },
  {
    name: "Заявки на врача (действия)",
    type: EnumPermissionsTypes.actions,
    key: EnumPermissionsKeys.applications,
    parent: null,
  },
  {
    name: "Специалисты",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.specialists,
    parent: null,
  },
  {
    name: "Специалисты (действия)",
    type: EnumPermissionsTypes.actions,
    key: EnumPermissionsKeys.specialists,
    parent: null,
  },
  {
    name: "Пользователи (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.users,
    parent: null,
  },
  {
    name: "Пользователи (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.users,
    parent: null,
  },
  {
    name: "Пользователи (изменение)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.users,
    parent: null,
  },
  {
    name: "Пользователи (начисления)",
    type: EnumPermissionsTypes.special,
    key: EnumPermissionsKeys.users,
    parent: null,
  },
  {
    name: "Пользователи (действия)",
    type: EnumPermissionsTypes.actions,
    key: EnumPermissionsKeys.users,
    parent: null,
  },
  {
    name: "Пользователи (импорт)",
    type: EnumPermissionsTypes.import,
    key: EnumPermissionsKeys.users,
    parent: null,
  },
  {
    name: "Обучения (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.education,
    parent: EnumPermissionsParents.dictionaries,
  },
  {
    name: "Обучения (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.education,
    parent: EnumPermissionsParents.dictionaries,
  },
  {
    name: "Обучения (изменение)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.education,
    parent: EnumPermissionsParents.dictionaries,
  },

  {
    name: "Тесты и опросы (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.contentForms,
    parent: EnumPermissionsParents.dictionaries,
  },
  {
    name: "Тесты и опросы (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.contentForms,
    parent: EnumPermissionsParents.dictionaries,
  },
  {
    name: "Тесты и опросы (изменение)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.contentForms,
    parent: EnumPermissionsParents.dictionaries,
  },
  {
    name: "Заказы (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.orders,
    parent: null,
  },
  {
    name: "Заказы (действия)",
    type: EnumPermissionsTypes.actions,
    key: EnumPermissionsKeys.orders,
    parent: null,
  },

  {
    name: "Направления",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.referrals,
    parent: null,
  },

  {
    name: "Консультации",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.consultations,
    parent: null,
  },
  {
    name: "Оффлайн консультации (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.offlineConsultations,
    parent: EnumPermissionsKeys.consultations,
  },
  {
    name: "Оффлайн консультации (редактирование)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.offlineConsultations,
    parent: EnumPermissionsKeys.consultations,
  },
  {
    name: "Онлайн консультации",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.onlineConsultations,
    parent: EnumPermissionsKeys.consultations,
  },

  {
    name: "Заявки на страховку (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.insurance,
    parent: null,
  },

  {
    name: "Страхование (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.insuranceProgram,
    parent: null,
  },
  {
    name: "Страхование (действия)",
    type: EnumPermissionsTypes.actions,
    key: EnumPermissionsKeys.insuranceProgram,
    parent: null,
  },

  {
    name: "Выплаты по стразованию (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.insuranceProgramPayments,
    parent: null,
  },
  {
    name: "Выплаты по стразованию (действия)",
    type: EnumPermissionsTypes.actions,
    key: EnumPermissionsKeys.insuranceProgramPayments,
    parent: null,
  },

  {
    name: "Отчеты",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.reports,
    parent: null,
  },

  {
    name: "Страны (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.countries,
    parent: EnumPermissionsParents.geo,
  },
  {
    name: "Страны (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.countries,
    parent: null,
  },
  {
    name: "Страны (редактирование)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.countries,
    parent: null,
  },
  {
    name: "Страны (удаление)",
    type: EnumPermissionsTypes.delete,
    key: EnumPermissionsKeys.countries,
    parent: null,
  },

  {
    name: "Города (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.cities,
    parent: EnumPermissionsParents.geo,
  },
  {
    name: "Города (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.cities,
    parent: null,
  },
  {
    name: "Города (редактирование)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.cities,
    parent: null,
  },
  {
    name: "Города (удаление)",
    type: EnumPermissionsTypes.delete,
    key: EnumPermissionsKeys.cities,
    parent: null,
  },

  {
    name: "Клиники (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.clinics,
    parent: EnumPermissionsParents.geo,
  },
  {
    name: "Клиники (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.clinics,
    parent: null,
  },
  {
    name: "Клиники (редактирование)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.clinics,
    parent: null,
  },
  {
    name: "Клиники (удаление)",
    type: EnumPermissionsTypes.delete,
    key: EnumPermissionsKeys.clinics,
    parent: null,
  },

  {
    name: "Регионы (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.regions,
    parent: EnumPermissionsParents.geo,
  },
  {
    name: "Регионы (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.regions,
    parent: null,
  },
  {
    name: "Регионы (редактирование)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.regions,
    parent: null,
  },
  {
    name: "Регионы (удаление)",
    type: EnumPermissionsTypes.delete,
    key: EnumPermissionsKeys.regions,
    parent: null,
  },

  {
    name: "Пункты (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.punkts,
    parent: EnumPermissionsParents.geo,
  },
  {
    name: "Пункты (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.punkts,
    parent: null,
  },
  {
    name: "Пункты (редактирование)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.punkts,
    parent: null,
  },
  {
    name: "Пункты (удаление)",
    type: EnumPermissionsTypes.delete,
    key: EnumPermissionsKeys.punkts,
    parent: null,
  },

  {
    name: "Системные (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.system,
    parent: EnumPermissionsKeys.system,
  },
  {
    name: "Системные (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.system,
    parent: null,
  },
  {
    name: "Системные (редактирование)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.system,
    parent: null,
  },
  {
    name: "Системные (удаление)",
    type: EnumPermissionsTypes.delete,
    key: EnumPermissionsKeys.system,
    parent: null,
  },

  {
    name: "Параметры стран",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.countryParams,
    parent: EnumPermissionsKeys.system,
  },

  {
    name: "Подписки (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.subscribes,
    parent: EnumPermissionsParents.dictionaries,
  },

  {
    name: "Программы подписок (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.subscribesPrograms,
    parent: EnumPermissionsParents.dictionaries,
  },
  {
    name: "Программы подписок (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.subscribesPrograms,
    parent: null,
  },
  {
    name: "Программы подписок (редактирование)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.subscribesPrograms,
    parent: null,
  },
  {
    name: "Программы подписок (удаление)",
    type: EnumPermissionsTypes.delete,
    key: EnumPermissionsKeys.subscribesPrograms,
    parent: null,
  },

  {
    name: "Услуги (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.services,
    parent: EnumPermissionsParents.dictionaries,
  },
  {
    name: "Услуги (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.services,
    parent: null,
  },
  {
    name: "Услуги (редактирование)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.services,
    parent: null,
  },
  {
    name: "Услуги (удаление)",
    type: EnumPermissionsTypes.delete,
    key: EnumPermissionsKeys.services,
    parent: null,
  },

  {
    name: "Прайс листы (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.prices,
    parent: EnumPermissionsParents.dictionaries,
  },
  {
    name: "Прайс листы (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.prices,
    parent: null,
  },
  {
    name: "Прайс листы (редактирование)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.prices,
    parent: null,
  },
  {
    name: "Прайс листы (удаление)",
    type: EnumPermissionsTypes.delete,
    key: EnumPermissionsKeys.prices,
    parent: null,
  },
  {
    name: "Прайс листы (обновление)",
    type: EnumPermissionsTypes.special,
    key: EnumPermissionsKeys.prices,
    parent: null,
  },

  {
    name: "Скидки (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.campaigns,
    parent: EnumPermissionsParents.dictionaries,
  },
  {
    name: "Скидки (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.campaigns,
    parent: null,
  },
  {
    name: "Скидки (редактирование)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.campaigns,
    parent: null,
  },
  {
    name: "Скидки (удаление)",
    type: EnumPermissionsTypes.delete,
    key: EnumPermissionsKeys.campaigns,
    parent: null,
  },

  {
    name: "Специализации (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.specializations,
    parent: EnumPermissionsParents.dictionaries,
  },
  {
    name: "Специализации (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.specializations,
    parent: null,
  },
  {
    name: "Специализации (редактирование)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.specializations,
    parent: null,
  },
  {
    name: "Специализации (удаление)",
    type: EnumPermissionsTypes.delete,
    key: EnumPermissionsKeys.specializations,
    parent: null,
  },

  {
    name: "Симптомы (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.symptoms,
    parent: EnumPermissionsParents.dictionaries,
  },
  {
    name: "Симптомы (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.symptoms,
    parent: null,
  },
  {
    name: "Симптомы (редактирование)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.symptoms,
    parent: null,
  },
  {
    name: "Симптомы (удаление)",
    type: EnumPermissionsTypes.delete,
    key: EnumPermissionsKeys.symptoms,
    parent: null,
  },

  {
    name: "Процедуры (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.procedures,
    parent: EnumPermissionsParents.dictionaries,
  },
  {
    name: "Процедуры (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.procedures,
    parent: null,
  },
  {
    name: "Процедуры (редактирование)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.procedures,
    parent: null,
  },
  {
    name: "Процедуры (удаление)",
    type: EnumPermissionsTypes.delete,
    key: EnumPermissionsKeys.procedures,
    parent: null,
  },

  {
    name: "Сопоставления (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.mappings,
    parent: EnumPermissionsParents.dictionaries,
  },
  {
    name: "Сопоставления (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.mappings,
    parent: null,
  },
  {
    name: "Сопоставления (редактирование)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.mappings,
    parent: null,
  },
  {
    name: "Сопоставления (удаление)",
    type: EnumPermissionsTypes.delete,
    key: EnumPermissionsKeys.mappings,
    parent: null,
  },

  {
    name: "Контенты (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.content,
    parent: EnumPermissionsParents.dictionaries,
  },
  {
    name: "Контенты (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.content,
    parent: null,
  },
  {
    name: "Контенты (редактирование)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.content,
    parent: null,
  },
  {
    name: "Контенты (удаление)",
    type: EnumPermissionsTypes.delete,
    key: EnumPermissionsKeys.content,
    parent: null,
  },
  {
    name: "Контенты (отправка пуш)",
    type: EnumPermissionsTypes.special,
    key: EnumPermissionsKeys.content,
    parent: null,
  },

  {
    name: "Лаборатории (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.laboratories,
    parent: EnumPermissionsParents.dictionaries,
  },
  {
    name: "Лаборатории (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.laboratories,
    parent: null,
  },
  {
    name: "Лаборатории (редактирование)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.laboratories,
    parent: null,
  },
  // {
  //   name: "Лаборатории (редактирование)",
  //   type: EnumPermissionsTypes.update,
  //   key: EnumPermissionsKeys.laboratories,
  //   parent: null,
  // },
  // {
  //   name: "Лаборатории (удаление)",
  //   type: EnumPermissionsTypes.delete,
  //   key: EnumPermissionsKeys.laboratories,
  //   parent: null,
  // },

  {
    name: "FAQ (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.faq,
    parent: EnumPermissionsParents.dictionaries,
  },
  {
    name: "FAQ (создание)",
    type: EnumPermissionsTypes.create,
    key: EnumPermissionsKeys.faq,
    parent: null,
  },
  {
    name: "FAQ (редактирование)",
    type: EnumPermissionsTypes.update,
    key: EnumPermissionsKeys.faq,
    parent: null,
  },
  {
    name: "FAQ (удаление)",
    type: EnumPermissionsTypes.delete,
    key: EnumPermissionsKeys.faq,
    parent: null,
  },

  {
    name: "Отзывы (просмотр)",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.reviews,
    parent: null,
  },
  {
    name: "Отзывы (модерация)",
    type: EnumPermissionsTypes.actions,
    key: EnumPermissionsKeys.reviews,
    parent: null,
  },

  {
    name: "Менеджеры",
    type: EnumPermissionsTypes.view,
    key: EnumPermissionsKeys.permissions,
    parent: null,
  },
];

export const restrictedUserClaimCode = "restrictedUser";

export const handleCreatePermissionCode = (key, type) => `${key}_${type}`;
