import { isEnviromentProd } from "helpers/enviroments";
import {
  EnumPermissionsKeys,
  EnumPermissionsParents,
  EnumPermissionsTypes,
  PagePermissions,
  restrictedUserClaimCode,
} from "services/permissions.service";

export const navigations = [
  {
    name: "Заказы",
    path: "/orders",
    icon: "shopping_cart_outlined_icon ",
    bg: "#56b3f5",
    key: EnumPermissionsKeys.orders,
  },
  {
    name: "Пользователи",
    path: "/users",
    icon: "group_icon",
    bg: "#ed9f20",
    key: EnumPermissionsKeys.users,
  },

  {
    name: "Заявки",
    icon: "notifications_icon",
    bg: "#f06964",
    key: EnumPermissionsKeys.applications,
    children: [
      {
        name: "Заявки на врача",
        path: "/applications",
        iconText: "CL",
      },
      {
        name: "Заявки на клинику",
        path: "/clinicApplications",
        iconText: "CL",
      },
    ],
  },

  {
    name: "Лотерея",
    path: "/lottery",
    icon: "emoji_events_icon  ",
    bg: "#FFB117",
    key: EnumPermissionsKeys.lottery,
  },
  {
    name: "Специалисты",
    icon: "engineering",
    bg: "#8d826c",
    key: EnumPermissionsKeys.specialists,
    children: [
      {
        name: "Список специалистов",
        path: "/specialists",
        iconText: "CL",
      },
      {
        name: "Настройка документов",
        path: "/docs-for-specialists",
        iconText: "CL",
      },
    ],
  },
  {
    name: "Направления",
    path: "/directions",
    icon: "colorize_icon",
    bg: "#30cbc6",
    key: EnumPermissionsKeys.referrals,
  },
  {
    name: "Акты",
    icon: "find_in_page",
    bg: "#ec5bcc",
    key: EnumPermissionsKeys.acts,
    children: [
      {
        name: "Все акты",
        path: "/acts",
        iconText: "CL",
      },
      {
        name: "Массовое подписание",
        path: "/signActs",
        iconText: "CL",
      },
    ],
  },

  {
    name: "Платежи",
    icon: "payments_icon",
    bg: "#60b010",
    key: EnumPermissionsParents.billing,
    children: [
      {
        name: "Все платежи",
        path: "/payments",
        iconText: "CL",
        key: EnumPermissionsKeys.billing,
      },
      {
        name: "Начисления врачам",
        path: "/doctor-fees",
        iconText: "CL",
        key: EnumPermissionsKeys.billing,
      },
      {
        name: "Начисления спец.",
        path: "/specialist-fees",
        iconText: "CL",
        key: EnumPermissionsKeys.specialistBilling,
      },
      // {
      //   name: "Доначисления",
      //   path: "/additional-fees",
      //   iconText: "CL",
      //   key: EnumPermissionsKeys.billing,
      // },
      {
        name: "Сетки регионов",
        path: "/grid-sales-region",
        iconText: "CL",
        key: EnumPermissionsKeys.procedures,
      },
    ],
  },

  {
    name: "Консультации",
    icon: "medical_services_icon",
    bg: "#309fcb",
    key: EnumPermissionsKeys.consultations,
    children: [
      {
        name: "Офлайн консультации",
        path: "/consultations/offline",
        iconText: "CL",
        key: EnumPermissionsKeys.offlineConsultations,
      },
      {
        name: "Онлайн консультации",
        path: "/consultations/online",
        iconText: "CL",
        key: EnumPermissionsKeys.onlineConsultations,
      },
    ],
  },

  {
    name: "Отчёты",
    icon: "assessment_icon",
    bg: "#b580e2",
    key: EnumPermissionsKeys.reports,
    children: [
      {
        name: "Аналитика заказов",
        path: "/dashboard/orders",
        iconText: "CL",
      },
      {
        name: "Общая аналитика",
        path: "/dashboard",
        iconText: "CL",
      },
      {
        name: "Новая аналитика",
        path: "/newDashboard",
        iconText: "CL",
      },
      {
        name: "Аналитика",
        path: "/analytics",
        iconText: "CL",
      },
      {
        name: "Статистика Контентов",
        path: "/content-statistics",
        iconText: "CL",
      },
    ],
  },

  {
    name: "Гео",
    icon: "public_icon ",
    bg: "#7945e8",
    key: EnumPermissionsParents.geo,
    children: [
      {
        name: "Страны",
        path: "/countries",
        icon: "flag_icon",
        key: EnumPermissionsKeys.countries,
      },
      {
        name: "Города",
        path: "/cities",
        icon: "location_city_icon ",
        key: EnumPermissionsKeys.cities,
      },
      {
        name: "Клиники",
        path: "/clinics",
        icon: "local_hospital_icon ",
        key: EnumPermissionsKeys.clinics,
      },
      {
        name: "Регионы",
        path: "/regions",
        icon: "explore_icon",
        key: EnumPermissionsKeys.regions,
      },
      {
        name: "Пункты",
        path: "/points",
        icon: "place_icon",
        key: EnumPermissionsKeys.punkts,
      },
    ],
  },

  {
    name: "Параметры",
    icon: "library_books_icon",
    bg: "#bb34c5",
    key: EnumPermissionsKeys.system,
    children: [
      {
        name: "Параметры стран",
        path: "/countryParams",
        icon: "language_icon",
        key: EnumPermissionsKeys.countryParams,
        isRequiredKey: true,
      },
      {
        name: "Языки",
        path: "/language",
        icon: "flag_icon",
        key: EnumPermissionsKeys.system,
      },
      {
        name: "Системные сообщения",
        path: "/systemMessage",
        icon: "speaker_notes_icon",
        key: EnumPermissionsKeys.system,
      },
      {
        name: "Системные параметры",
        path: "/systemParam",
        icon: "settings_applications_icon ",
        key: EnumPermissionsKeys.system,
      },
      {
        name: "Скопы пользователей",
        path: "/scopes",
        icon: "folder_shared",
        key: EnumPermissionsKeys.system,
      },
    ],
  },

  {
    name: "Справочники",
    icon: "format_list_bulleted_icon",
    bg: "#6dc534",
    key: EnumPermissionsParents.dictionaries,
    children: [
      {
        name: "Услуги",
        path: "/services",
        icon: "design_services_icon  ",
        key: EnumPermissionsKeys.services,
      },
      {
        name: "Прайс-листы",
        path: "/prices",
        icon: "price_change_icon  ",
        key: EnumPermissionsKeys.prices,
      },
      {
        name: "Подписки",
        path: "/subscribes",
        icon: "diamond",
        key: EnumPermissionsKeys.subscribes,
      },
      {
        name: "Социальные Статусы",
        path: "/social-statuses",
        icon: "accessible_icon",
        key: EnumPermissionsKeys.socialStatuses,
      },
      {
        name: "Скидки",
        path: "/campaigns",
        icon: "receipt_icon",
        key: EnumPermissionsKeys.campaigns,
      },
      {
        name: "Специализации",
        path: "/specializations",
        icon: "settings_suggest_icon",
        key: EnumPermissionsKeys.specializations,
      },
      {
        name: "Симптомы",
        path: "/symptoms",
        icon: "coronavirus_icon",
        key: EnumPermissionsKeys.symptoms,
      },
      {
        name: "Процедуры",
        path: "/procedures",
        icon: "medical_information_icon",
        key: EnumPermissionsKeys.procedures,
      },
      {
        name: "Сопоставления",
        icon: "public_icon",
        key: EnumPermissionsKeys.mappings,
        children: [
          {
            name: "По сервисам",
            path: "/mapping/service",
            iconText: "CL",
          },
          {
            name: "По регионам",
            path: "/mapping/region",
            iconText: "CL",
          },
        ],
      },
      {
        name: "Контенты",
        path: "/content",
        icon: "folder_copy_icon",
        key: EnumPermissionsKeys.content,
      },
      {
        name: "Отзывы",
        path: "/reviews",
        icon: "reviews",
        key: EnumPermissionsKeys.content,
      },
      {
        name: "Уведомления",
        path: "/push-history",
        icon: "notifications",
        key: EnumPermissionsKeys.content,
      },
      {
        name: "Лаборатории",
        path: "/laboratories",
        icon: "biotech_icon",
        key: EnumPermissionsKeys.laboratories,
      },
      {
        name: "Вопросы и ответы",
        path: "/faq",
        icon: "question_mark_icon",
        key: EnumPermissionsKeys.faq,
      },
      {
        name: "Обучение",
        path: "/education",
        icon: "school_icon",
        key: EnumPermissionsKeys.education,
      },
      {
        name: "Тесты и опросы",
        path: "/content-forms",
        icon: "quiz_icon",
        key: EnumPermissionsKeys.contentForms,
      },
      {
        name: "Вакансии",
        path: "/vacancies",
        icon: "work_outline_icon",
        key: EnumPermissionsKeys.vacancies,
      },
    ],
  },

  {
    name: "Страхование",
    icon: "stars_icon",
    bg: "#FE4613",
    key: EnumPermissionsKeys.insuranceProgram,
    children: [
      {
        name: "Страхование зарубеж",
        path: "/insurance-applications",
        iconText: "CL",
        key: EnumPermissionsKeys.insurance,
      },
      {
        name: "Клиенты",
        path: "/insurance/clients",
        iconText: "CL",
        key: EnumPermissionsKeys.insuranceProgram,
      },
      {
        name: "Заказы",
        path: "/insurance/orders",
        iconText: "CL",
        key: EnumPermissionsKeys.insuranceProgram,
      },
      {
        name: "Выплаты",
        path: "/insurance/payments",
        iconText: "CL",
        key: EnumPermissionsKeys.insuranceProgramPayments,
        children: [
          {
            name: "Заявки на выплаты",
            path: "/insurance/payments/applications",
            iconText: "CL",
            key: EnumPermissionsKeys.insuranceProgramPayments,
          },
          {
            name: "Типы выплат",
            path: "/insurance/payments/types",
            iconText: "CL",
            key: EnumPermissionsKeys.insuranceProgramPayments,
          },
        ],
      },
    ],
  },

  {
    name: "Менеджеры",
    path: "/managers",
    icon: "manage_accounts_icon",
    bg: "#ff0000",
    key: EnumPermissionsKeys.permissions,
    isRequiredKey: true,
  },

  {
    name: "Поддержка",
    icon: "help_icon",
    bg: "#bb34c5",
    key: EnumPermissionsKeys.support,
    children: [
      {
        name: "Обращения",
        path: "/support/tickets",
        icon: "help_icon",
        key: EnumPermissionsKeys.support,
      },
      {
        name: "Типы обращений",
        path: "/support/ticketTypes",
        icon: "help_icon",
        key: EnumPermissionsKeys.support,
        isRequiredKey: true,
      },
    ],
  },
];

const isPageIsInPerms = (perms, page, isRestrictedUser) => {
  if (page?.key) {
    const isInPerm = perms.some(perm => {
      const [key, type] = perm.value.split("_");
      return key === page.key && +type === EnumPermissionsTypes.view;
    });

    if (page?.isRequiredKey && isEnviromentProd()) {
      return isInPerm;
    } else if (isRestrictedUser) {
      return isInPerm;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const getFilteredNavigations = perms => {
  const isRestrictedUser = Boolean(
    perms && perms?.some(claim => claim.value === restrictedUserClaimCode),
  );

  const filteredNavigations = [];

  navigations.forEach(page => {
    const isNoChildrenOrEveryChildrenNoSelfKey =
      !page?.children || page?.children.every(child => !child?.key);

    if (isNoChildrenOrEveryChildrenNoSelfKey) {
      if (isPageIsInPerms(perms, page, isRestrictedUser)) {
        filteredNavigations.push(page);
      }
    } else {
      const isPageNavFolderIsActive = perms.some(perm => {
        const [key] = perm.value.split("_");
        return (
          PagePermissions.find(item => item.key === key)?.parent === page.key ||
          page.key === key
        );
      });

      if (isPageNavFolderIsActive || !isRestrictedUser) {
        const filteredChildren = page.children.filter(child =>
          isPageIsInPerms(perms, child, isRestrictedUser),
        );

        const newPage = { ...page };
        newPage.children = filteredChildren;
        filteredNavigations.push(newPage);
      }
    }
  });

  return filteredNavigations;
};
