import Loadable from "components/Loadable";
import ContentFormsAnswers from "pages/ContentForms/ContentFormsAnswers";
import { lazy } from "react";
import {
  EnumPermissionsKeys,
  EnumPermissionsTypes,
  handleCreatePermissionCode,
} from "services/permissions.service";
import ScopeLayout from "../../shared/ScopeLayout";

const Users = Loadable(lazy(() => import("pages/Users")));
const SocialStatusView = Loadable(
  lazy(() => import("pages/SocialStatus/components/SocialStatusView")),
);
const SocialStatusCreate = Loadable(
  lazy(() => import("pages/SocialStatus/components/SocialStatusCreate")),
);
const SocialStatusEdit = Loadable(
  lazy(() => import("pages/SocialStatus/components/SocialStatusEdit")),
);
const SocialStatus = Loadable(
  lazy(() => import("pages/SocialStatus/components/SocialStatusCreate")),
);
const ContentStatistic = Loadable(
  lazy(() => import("pages/ContentStatistic/ContentStatistic")),
);
const DictionarySystemParamEdit = Loadable(
  lazy(
    () => import("../pages/Dictionary/SystemParam/DictionarySystemParamEdit"),
  ),
);
const DoctorFeesFiles = Loadable(
  lazy(() => import("pages/DoctorFees/DoctorFeesFiles")),
);
const OfflineConsultationDetails = Loadable(
  lazy(
    () =>
      import(
        "pages/Consultations/OfflineConsultations/OfflineConsultationDetails/OfflineConsultationDetails"
      ),
  ),
);
const ClinicPointsDetails = Loadable(
  lazy(() => import("pages/Clinics/ClinicsPoints/ClinicPointsDetails")),
);
const TicketTypes = Loadable(
  lazy(() => import("app/pages/Support/TicketTypes")),
);
const TicketTypeForm = Loadable(
  lazy(() => import("app/pages/Support/TicketTypeForm")),
);
const TicketList = Loadable(lazy(() => import("app/pages/Support/TicketList")));
const TicketForm = Loadable(lazy(() => import("app/pages/Support/TicketForm")));
const GridSalesRegion = Loadable(
  lazy(() => import("../../pages/GridSalesRegion/GridSalesRegion")),
);
const GridSalesRegionCreate = Loadable(
  lazy(
    () =>
      import(
        "../../pages/GridSalesRegion/GridSalesRegionCreate/GridSalesRegionCreate"
      ),
  ),
);
const GridSalesRegionEdit = Loadable(
  lazy(
    () =>
      import(
        "../../pages/GridSalesRegion/GridSalesRegionEdit/GridSalesRegionEdit"
      ),
  ),
);
//laboratories
const LaboratoriesPage = Loadable(
  lazy(() => import("../../pages/Laboratories")),
);
const LabCreate = Loadable(
  lazy(
    () => import("../../pages/Laboratories/LaboratoryCreate/LaboratoryCreate"),
  ),
);
const LabEdit = Loadable(
  lazy(() => import("../../pages/Laboratories/LaboratoryEdit/LaboratoryEdit")),
);
const LabView = Loadable(
  lazy(
    () =>
      import("../../pages/Laboratories/LaboratoryDetails/LaboratoryDetails"),
  ),
);
//orders
const Orders = Loadable(lazy(() => import("../../pages/Orders")));
const OrderDetails = Loadable(
  lazy(() => import("../pages/Orders/OrderDetails")),
);
//Points
const Points = Loadable(lazy(() => import("pages/Points")));

//RegionsCrud
const RegionsList = Loadable(lazy(() => import("../pages/Regions/Regions")));
const RegionCreate = Loadable(
  lazy(() => import("../pages/Regions/RegionsCreate")),
);
const RegionEdit = Loadable(lazy(() => import("../pages/Regions/RegionEdit")));

//faq
const FaqPage = Loadable(lazy(() => import("../pages/Faq/FaqPage")));
const EditFaq = Loadable(lazy(() => import("../pages/Faq/EditFaq")));
const CreateFaq = Loadable(lazy(() => import("../pages/Faq/CreateFaq")));

//education
const Education = Loadable(lazy(() => import("pages/Education/Education")));
const EducationView = Loadable(
  lazy(() => import("pages/Education/EducationView")),
);
const EducationCreate = Loadable(
  lazy(() => import("pages/Education/EducationCreate")),
);
const EducationEdit = Loadable(
  lazy(() => import("pages/Education/EducationEdit")),
);
//education form
const ContentForms = Loadable(lazy(() => import("pages/ContentForms")));
const ContentFormsView = Loadable(
  lazy(() => import("pages/ContentForms/ContentFormsView")),
);
const ContentFormsCreate = Loadable(
  lazy(() => import("pages/ContentForms/ContentFormsCreate")),
);
const ContentFormsEdit = Loadable(
  lazy(() => import("pages/ContentForms/ContentFormsEdit")),
);
//users
const UserOrders = Loadable(
  lazy(() => import("../pages/Users/user-components/UserOrders")),
);
const UserReferrals = Loadable(
  lazy(() => import("../pages/Users/user-components/UserReferrals")),
);
const UserInfo = Loadable(
  lazy(() => import("../pages/Users/user-components/UserInfo")),
);
const UserDetailsLayout = Loadable(
  lazy(() => import("../pages/Users/UserDetailsLayout")),
);
const UserDoctorDetails = Loadable(
  lazy(() => import("../pages/Users/user-components/UserDoctorDetails")),
);
const UserNurseOrders = Loadable(
  lazy(() => import("../pages/Users/user-components/NurseOrders")),
);
const UserCreate = Loadable(
  lazy(() => import("../pages/Users/UserCreatePage")),
);
const UserUpdate = Loadable(
  lazy(() => import("../pages/Users/UserUpdatePage")),
);
const UserFees = Loadable(
  lazy(() => import("../pages/Users/user-components/UserFees")),
);
const UserDoctorFee = Loadable(
  lazy(() => import("../pages/Users/user-components/DoctorFees")),
);
const HealthAssessment = Loadable(
  lazy(() => import("app/pages/Users/user-components/HealthAssessment")),
);
//Countries
const Countries = Loadable(lazy(() => import("../pages/Countries/Countries")));
const Cities = Loadable(lazy(() => import("../pages/Cities/Cities")));

//clinics
const Clinics = Loadable(lazy(() => import("../../pages/Clinics")));
const ClinicsCreate = Loadable(
  lazy(() => import("../../pages/Clinics/ClinicCreate")),
);
const ClinicsEdit = Loadable(
  lazy(() => import("../../pages/Clinics/ClinicEdit")),
);
const ClinicsPoints = Loadable(
  lazy(() => import("pages/Clinics/ClinicsPoints")),
);
const ClinicsPointsCreate = Loadable(
  lazy(() => import("pages/Clinics/ClinicsPoints/ClinicsPointsCreate")),
);
//applications
const ClinicApplications = Loadable(
  lazy(() => import("../../pages/ClinicApplications")),
);
const Applications = Loadable(lazy(() => import("../../pages/Applications")));
const ApplicationDetails = Loadable(
  lazy(
    () => import("../pages/Applications/Components/Details/ApplicationDetails"),
  ),
);

// consultations
const OfflineConsultations = Loadable(
  lazy(() => import("../../pages/Consultations/OfflineConsultations")),
);
const EditOfflineConsultation = Loadable(
  lazy(() => import("../../pages/Consultations/EditOfflineConsultation")),
);
const OnlineConsultations = Loadable(
  lazy(() => import("../../pages/Consultations/OnlineConsultations")),
);

//payments
const Payments = Loadable(lazy(() => import("../../pages/Fees")));

//Directions
const Referrals = Loadable(lazy(() => import("../../pages/Referrals")));

//services
const ServiceTypesPage = Loadable(
  lazy(() => import("../../pages/ServiceTypes/ServiceTypes")),
);
const ServicesPage = Loadable(lazy(() => import("../../pages/Services")));
const ServiceDetails = Loadable(
  lazy(() => import("../../pages/Services/ServiceView")),
);
const CreateService = Loadable(
  lazy(() => import("../../pages/Services/ServiceCreate")),
);
const EditService = Loadable(
  lazy(() => import("../../pages/Services/ServiceEdit")),
);
//prices
const NewPricesPage = Loadable(lazy(() => import("../../pages/Prices")));
const ProviderPrices = Loadable(
  lazy(() => import("pages/Prices/ProviderPrices")),
);
const PricesCreate = Loadable(
  lazy(() => import("../../pages/Prices/PricesCreate")),
);
const PricesEdit = Loadable(
  lazy(() => import("../../pages/Prices/PricesEdit")),
);

//campaigns
const CampaignsPage = Loadable(lazy(() => import("pages/Campaigns")));
const CampaignsDetailsPage = Loadable(
  lazy(() => import("../pages/Campaigns/CampaignDetails")),
);
const CreateCampaign = Loadable(
  lazy(() => import("pages/Campaigns/CampaignsCreate")),
);
const EditCampaign = Loadable(
  lazy(() => import("pages/Campaigns/CampaignsEdit")),
);
//specializations
const SpecializationsPage = Loadable(
  lazy(() => import("../../pages/Specializations")),
);
const SpecializationsCreatePage = Loadable(
  lazy(() => import("../../pages/Specializations/SpecializationCreate")),
);
const SpecializationsEditPage = Loadable(
  lazy(() => import("../../pages/Specializations/SpecializationEdit")),
);
//symptoms
const SymptomsPage = Loadable(lazy(() => import("../pages/Symptoms")));
const SymptomsCreatePage = Loadable(
  lazy(() => import("../pages/Symptoms/CreateSymptoms")),
);
const SymptomsEditPage = Loadable(
  lazy(() => import("../pages/Symptoms/EditSymptoms")),
);
//mappings
const MappingByRegion = Loadable(
  lazy(() => import("../../pages/Mappings/MappingByRegion")),
);
const MappingService = Loadable(
  lazy(() => import("../pages/Mappings/MappingService")),
);
const MappingServiceEdit = Loadable(
  lazy(() => import("../pages/Mappings/MappingService/mappingServiceEdit")),
);
const MappingServiceCreate = Loadable(
  lazy(() => import("../pages/Mappings/MappingService/mappingServiceCreate")),
);
const MappingRegionEdit = Loadable(
  lazy(() => import("../pages/Mappings/MappingByRegion/mappingRegionEdit")),
);
const MappingRegionCreate = Loadable(
  lazy(() => import("../pages/Mappings/MappingByRegion/mappingRegionCreate")),
);
//procedures
const ProceduresPage = Loadable(lazy(() => import("../pages/Procedure")));
const CreateProcedures = Loadable(
  lazy(() => import("../pages/Procedure/CreateProcedures")),
);
const EditProcedures = Loadable(
  lazy(() => import("../pages/Procedure/EditProcedures")),
);
//content
const Content = Loadable(lazy(() => import("../../pages/Content")));
const ContentCreate = Loadable(
  lazy(() => import("../../pages/Content/ContentCreate")),
);
const ContentCreateSelectSettings = Loadable(
  lazy(
    () =>
      import("../../pages/Content/ContentCreate/ContentCreateSelectSettings"),
  ),
);
const ContentEdit = Loadable(
  lazy(() => import("../../pages/Content/ContentEdit")),
);
const ContentView = Loadable(lazy(() => import("pages/Content/ContentView")));

//Dictionary
//Dict: CountryParams
const CountryParams = Loadable(
  lazy(() => import("../pages/Dictionary/CountryParam/DictionaryCountryParam")),
);
const CountryParamsCreate = Loadable(
  lazy(
    () =>
      import("../pages/Dictionary/CountryParam/DictionaryCountryParamCreate"),
  ),
);
//Dict: Language
const Language = Loadable(
  lazy(() => import("../pages/Dictionary/Languages/DictionaryLanguage")),
);
const LanguageCreate = Loadable(
  lazy(() => import("../pages/Dictionary/Languages/DictionaryLanguageCreate")),
);
//Dict: SystemMessage
const SystemMessage = Loadable(
  lazy(() => import("../pages/Dictionary/SystemMessages/SystemMessage")),
);
const SystemMessageCreate = Loadable(
  lazy(() => import("../pages/Dictionary/SystemMessages/SystemMessageCreate")),
);
//Dict: SystemParam
const SystemParam = Loadable(
  lazy(() => import("../pages/Dictionary/SystemParam/DictionarySystemParam")),
);
const SystemParamCreate = Loadable(
  lazy(
    () => import("../pages/Dictionary/SystemParam/DictionarySystemParamCreate"),
  ),
);

//Specialists
const Specialists = Loadable(
  lazy(() => import("../../pages/Specialists/Specialists")),
);

//Documents for Specialists
const DocsForSpecialists = Loadable(
  lazy(() => import("../../pages/DocsForSpecialists/index")),
);

//Scopes
const Scopes = Loadable(lazy(() => import("../../pages/Scopes/index")));

//Managers
const Managers = Loadable(lazy(() => import("../../pages/Managers/index")));
const ManagersCreate = Loadable(
  lazy(() => import("../../pages/Managers/ManagersCreate")),
);
const ManagersEdit = Loadable(
  lazy(() => import("../../pages/Managers/ManagersEdit")),
);

//Claims
// const Claims = Loadable(lazy(() => import("../../pages/Claims")));

//DoctorFees2
const DoctorFees2 = Loadable(
  lazy(() => import("../../pages/DoctorFees2/DoctorFees2")),
);
const DoctorFees2Accrue = Loadable(
  lazy(() => import("../../pages/DoctorFees2/DoctorFees2Accrue")),
);
const DoctorFees2Create = Loadable(
  lazy(() => import("../../pages/DoctorFees2/DoctorFees2Create")),
);
const AdditionalDoctorFeesHistory = Loadable(
  lazy(
    () =>
      import(
        "../../pages/AdditionalDoctorFees/AdditionalDoctorFeesHistory/AdditionalDoctorFeesHistory"
      ),
  ),
);
//doctor fees
const DoctorFees = Loadable(lazy(() => import("../../pages/DoctorFees")));
const DoctorFeesAccrue = Loadable(
  lazy(() => import("../../pages/DoctorFees/DoctorFeesAccrue")),
);

const PushHistory = Loadable(
  lazy(() => import("../../pages/PushHistory/PushHistory")),
);

const Subscribes = Loadable(
  lazy(() => import("../../pages/Subscribes/Subscribes")),
);

const SubscribesPrograms = Loadable(
  lazy(
    () => import("../../pages/Subscribes/SubscribePrograms/SubscribePrograms"),
  ),
);

const SubscribesProgramsCreate = Loadable(
  lazy(
    () =>
      import(
        "../../pages/Subscribes/SubscribePrograms/SubscribeProgramsCreate/SubscribeProgramsCreate"
      ),
  ),
);

const SubscribesProgramsEdit = Loadable(
  lazy(
    () =>
      import(
        "../../pages/Subscribes/SubscribePrograms/SubscribeProgramsEdit/SubscribeProgramsEdit"
      ),
  ),
);
const VacanciesCreate = Loadable(
  lazy(
    () => import("pages/Vacancies/components/VacanciesCreate/VacanciesCreate"),
  ),
);

const Vacancies = Loadable(lazy(() => import("pages/Vacancies")));

const VacanciesEdit = Loadable(
  lazy(() => import("pages/Vacancies/components/VacanciesEdit/VacanciesEdit")),
);

const Reviews = Loadable(lazy(() => import("pages/Reviews/Reviews")));

const Acts = Loadable(lazy(() => import("pages/Acts/Acts")));
const ActsSign = Loadable(lazy(() => import("pages/Acts/ActsSign")));
const InsuranceApplications = Loadable(
  lazy(() => import("pages/InsuranceApplications")),
);

const InsuranceClients = Loadable(
  lazy(() => import("pages/Insurance/InsuranceClients")),
);
const InsurancePaymentApplications = Loadable(
  lazy(() => import("pages/Insurance/InsurancePaymentApplications")),
);
const InsurancePaymentApplicationDetails = Loadable(
  lazy(() => import("pages/Insurance/InsurancePaymentApplicationDetails")),
);
const InsurancePaymentTypes = Loadable(
  lazy(() => import("pages/Insurance/InsurancePaymentTypes")),
);
const InsurancePaymentTypesForm = Loadable(
  lazy(() => import("pages/Insurance/InsurancePaymentTypesForm")),
);

const InsurancePaymentFiles = Loadable(
  lazy(() => import("pages/Insurance/InsurancePaymentFiles")),
);
const InsurancePaymentCreateFilesForm = Loadable(
  lazy(() => import("pages/Insurance/InsurancePaymentCreateFilesForm")),
);

const Lottery = Loadable(lazy(() => import("pages/Lottery")));
const LotteryCreate = Loadable(
  lazy(() => import("pages/Lottery/LotteryCreate/LotteryCreate")),
);
const LotteryEdit = Loadable(
  lazy(() => import("pages/Lottery/LotteryEdit/LotteryEdit")),
);
const LotteryDetails = Loadable(
  lazy(() => import("pages/Lottery/LotteryDetails/LotteryDetails")),
);

export const mainRoutes = [
  //reviews
  {
    path: "/reviews",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.reviews,
          EnumPermissionsTypes.view,
        )}
      >
        <Reviews />
      </ScopeLayout>
    ),
  },
  // Lottery

  {
    path: "/lottery",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.lottery,
          EnumPermissionsTypes.view,
        )}
      >
        <Lottery />
      </ScopeLayout>
    ),
  },
  {
    path: "/lottery/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.lottery,
          EnumPermissionsTypes.create,
        )}
      >
        <LotteryCreate />
      </ScopeLayout>
    ),
  },
  {
    path: "/lottery/edit/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.lottery,
          EnumPermissionsTypes.update,
        )}
      >
        <LotteryEdit />
      </ScopeLayout>
    ),
  },
  {
    path: "/lottery/view/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.lottery,
          EnumPermissionsTypes.view,
        )}
      >
        <LotteryDetails />
      </ScopeLayout>
    ),
  },

  //Acts
  {
    path: "/acts",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.acts,
          EnumPermissionsTypes.view,
        )}
      >
        <Acts />
      </ScopeLayout>
    ),
  },
  {
    path: "/signActs",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.acts,
          EnumPermissionsTypes.view,
        )}
      >
        <ActsSign />
      </ScopeLayout>
    ),
  },

  //subscribes
  {
    path: "/subscribes",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.subscribes,
          EnumPermissionsTypes.view,
        )}
      >
        <Subscribes />
      </ScopeLayout>
    ),
  },

  {
    path: "/subscribes/programs",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.subscribes,
          EnumPermissionsTypes.view,
        )}
      >
        <SubscribesPrograms />
      </ScopeLayout>
    ),
  },
  {
    path: "/subscribes/programs/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.subscribes,
          EnumPermissionsTypes.create,
        )}
      >
        <SubscribesProgramsCreate />
      </ScopeLayout>
    ),
  },
  {
    path: "/subscribes/programs/update/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.subscribes,
          EnumPermissionsTypes.update,
        )}
      >
        <SubscribesProgramsEdit />
      </ScopeLayout>
    ),
  },

  //push history
  {
    path: "/push-history",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.content,
          EnumPermissionsTypes.view,
        )}
      >
        <PushHistory />
      </ScopeLayout>
    ),
  },

  //specialists
  {
    path: "/specialists",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.specialists,
          EnumPermissionsTypes.view,
        )}
      >
        <Specialists />
      </ScopeLayout>
    ),
  },

  //docs-for-specialists
  {
    path: "/docs-for-specialists",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.specialists,
          EnumPermissionsTypes.view,
        )}
      >
        <DocsForSpecialists />
      </ScopeLayout>
    ),
  },

  {
    path: "/insurance-applications",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.insurance,
          EnumPermissionsTypes.view,
        )}
      >
        <InsuranceApplications />
      </ScopeLayout>
    ),
  },

  {
    path: "/insurance/clients",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.insuranceProgram,
          EnumPermissionsTypes.view,
        )}
      >
        <InsuranceClients />
      </ScopeLayout>
    ),
  },
  {
    path: "/insurance/orders",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.insuranceProgram,
          EnumPermissionsTypes.view,
        )}
      >
        <Orders isInsurance />
      </ScopeLayout>
    ),
  },
  {
    path: "/insurance/payments/applications",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.insuranceProgramPayments,
          EnumPermissionsTypes.view,
        )}
      >
        <InsurancePaymentApplications />
      </ScopeLayout>
    ),
  },
  {
    path: "/insurance/payments/applications/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.insuranceProgramPayments,
          EnumPermissionsTypes.view,
        )}
      >
        <InsurancePaymentApplicationDetails />
      </ScopeLayout>
    ),
  },
  {
    path: "/insurance/payments/types",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.insuranceProgramPayments,
          EnumPermissionsTypes.view,
        )}
      >
        <InsurancePaymentTypes />
      </ScopeLayout>
    ),
  },
  {
    path: "/insurance/payments/types/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.insuranceProgramPayments,
          EnumPermissionsTypes.actions,
        )}
      >
        <InsurancePaymentTypesForm />
      </ScopeLayout>
    ),
  },
  {
    path: "/insurance/payments/types/edit",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.insuranceProgramPayments,
          EnumPermissionsTypes.actions,
        )}
      >
        <InsurancePaymentTypesForm />
      </ScopeLayout>
    ),
  },
  {
    path: "/insurance/payments/files/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.insuranceProgramPayments,
          EnumPermissionsTypes.view,
        )}
      >
        <InsurancePaymentFiles />
      </ScopeLayout>
    ),
  },
  {
    path: "/insurance/payments/files/:id/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.insuranceProgramPayments,
          EnumPermissionsTypes.actions,
        )}
      >
        <InsurancePaymentCreateFilesForm />
      </ScopeLayout>
    ),
  },
  {
    path: "/insurance/payments/files/:id/edit",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.insuranceProgramPayments,
          EnumPermissionsTypes.actions,
        )}
      >
        <InsurancePaymentCreateFilesForm />
      </ScopeLayout>
    ),
  },

  // social statuses
  {
    path: "/social-statuses",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.socialStatuses,
          EnumPermissionsTypes.view,
        )}
      >
        <SocialStatus />
      </ScopeLayout>
    ),
  },

  {
    path: "/social-statuses/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.socialStatuses,
          EnumPermissionsTypes.create,
        )}
      >
        <SocialStatusCreate />
      </ScopeLayout>
    ),
  },
  {
    path: "/social-statuses/edit/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.socialStatuses,
          EnumPermissionsTypes.update,
        )}
      >
        <SocialStatusEdit />
      </ScopeLayout>
    ),
  },
  {
    path: "/social-statuses/view/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.socialStatuses,
          EnumPermissionsTypes.view,
        )}
      >
        <SocialStatusView />
      </ScopeLayout>
    ),
  },

  //scopes
  {
    path: "/scopes",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.system,
          EnumPermissionsTypes.view,
        )}
      >
        <Scopes />
      </ScopeLayout>
    ),
  },

  //claims
  // {
  //   path: "/claims",
  //   element: (
  //     <ScopeLayout
  //       permissionKey={handleCreatePermissionCode(
  //         EnumPermissionsKeys.claims,
  //         EnumPermissionsTypes.view,
  //       )}
  //     >
  //       <Claims />
  //     </ScopeLayout>
  //   ),
  // },

  //managers
  {
    path: "/managers",
    element: (
      <ScopeLayout
        isRequiredKey
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.permissions,
          EnumPermissionsTypes.view,
        )}
      >
        <Managers />
      </ScopeLayout>
    ),
  },
  {
    path: "/managers/create",
    element: (
      <ScopeLayout
        isRequiredKey
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.permissions,
          EnumPermissionsTypes.view,
        )}
      >
        <ManagersCreate />
      </ScopeLayout>
    ),
  },
  {
    path: "/managers/edit/:phoneNumber/:adminId",
    element: (
      <ScopeLayout
        isRequiredKey
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.permissions,
          EnumPermissionsTypes.view,
        )}
      >
        <ManagersEdit />
      </ScopeLayout>
    ),
  },

  //labs
  {
    path: "/laboratories",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.laboratories,
          EnumPermissionsTypes.view,
        )}
      >
        <LaboratoriesPage />
      </ScopeLayout>
    ),
  },
  {
    path: "/laboratories/create",
    element: (
      <ScopeLayout
        isRequiredKey
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.laboratories,
          EnumPermissionsTypes.create,
        )}
      >
        <LabCreate />
      </ScopeLayout>
    ),
  },
  {
    path: "/laboratories/view/:id/:provider",
    element: (
      <ScopeLayout
        isRequiredKey
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.laboratories,
          EnumPermissionsTypes.view,
        )}
      >
        <LabView />
      </ScopeLayout>
    ),
  },
  {
    path: "/laboratories/edit/:id/:provider",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.laboratories,
          EnumPermissionsTypes.update,
        )}
      >
        <LabEdit />
      </ScopeLayout>
    ),
  },

  //orders
  {
    path: "/orders",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.orders,
          EnumPermissionsTypes.view,
        )}
      >
        <Orders />
      </ScopeLayout>
    ),
  },
  {
    path: "/orders/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.orders,
          EnumPermissionsTypes.view,
        )}
      >
        <OrderDetails />
      </ScopeLayout>
    ),
  },

  //Points
  {
    path: "/points",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.punkts,
          EnumPermissionsTypes.view,
        )}
      >
        <Points />
      </ScopeLayout>
    ),
  },

  //content
  {
    path: "/content",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.content,
          EnumPermissionsTypes.view,
        )}
      >
        <Content />
      </ScopeLayout>
    ),
  },
  {
    path: "/content/create/forms",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.content,
          EnumPermissionsTypes.create,
        )}
      >
        <ContentCreate />
      </ScopeLayout>
    ),
  },
  {
    path: "/content/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.content,
          EnumPermissionsTypes.create,
        )}
      >
        <ContentCreateSelectSettings />
      </ScopeLayout>
    ),
  },
  {
    path: "/content/edit/:queryType/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.content,
          EnumPermissionsTypes.update,
        )}
      >
        <ContentEdit />
      </ScopeLayout>
    ),
  },
  {
    path: "/content/view/:queryType/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.content,
          EnumPermissionsTypes.view,
        )}
      >
        <ContentView />
      </ScopeLayout>
    ),
  },

  //faq
  {
    path: "/faq",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.faq,
          EnumPermissionsTypes.view,
        )}
      >
        <FaqPage />
      </ScopeLayout>
    ),
  },
  {
    path: "/faq/edit/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.faq,
          EnumPermissionsTypes.update,
        )}
      >
        <EditFaq />
      </ScopeLayout>
    ),
  },
  {
    path: "/faq/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.faq,
          EnumPermissionsTypes.create,
        )}
      >
        <CreateFaq />
      </ScopeLayout>
    ),
  },

  //education
  {
    path: "/education",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.education,
          EnumPermissionsTypes.view,
        )}
      >
        <Education />
      </ScopeLayout>
    ),
  },
  {
    path: "/education/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.education,
          EnumPermissionsTypes.view,
        )}
      >
        <EducationView />
      </ScopeLayout>
    ),
  },
  {
    path: "/education/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.education,
          EnumPermissionsTypes.create,
        )}
      >
        <EducationCreate />
      </ScopeLayout>
    ),
  },
  {
    path: "/education/edit/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.education,
          EnumPermissionsTypes.update,
        )}
      >
        <EducationEdit />
      </ScopeLayout>
    ),
  },
  // test and survey
  {
    path: "/content-forms",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.contentForms,
          EnumPermissionsTypes.view,
        )}
      >
        <ContentForms />
      </ScopeLayout>
    ),
  },
  {
    path: "/content-forms/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.contentForms,
          EnumPermissionsTypes.view,
        )}
      >
        <ContentFormsView />
      </ScopeLayout>
    ),
  },
  {
    path: "/content-forms/answers/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.contentForms,
          EnumPermissionsTypes.view,
        )}
      >
        <ContentFormsAnswers />
      </ScopeLayout>
    ),
  },
  {
    path: "/content-forms/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.contentForms,
          EnumPermissionsTypes.create,
        )}
      >
        <ContentFormsCreate />
      </ScopeLayout>
    ),
  },
  {
    path: "/content-forms/edit/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.contentForms,
          EnumPermissionsTypes.update,
        )}
      >
        <ContentFormsEdit />
      </ScopeLayout>
    ),
  },
  //consultations
  {
    path: "/consultations/offline",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.offlineConsultations,
          EnumPermissionsTypes.view,
        )}
      >
        <OfflineConsultations />
      </ScopeLayout>
    ),
  },
  {
    path: "/consultations/offline/:id/edit",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.offlineConsultations,
          EnumPermissionsTypes.update,
        )}
      >
        <EditOfflineConsultation />
      </ScopeLayout>
    ),
  },
  {
    path: "/consultations/offline/details/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.offlineConsultations,
          EnumPermissionsTypes.view,
        )}
      >
        <OfflineConsultationDetails />
      </ScopeLayout>
    ),
  },
  {
    path: "/consultations/online",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.onlineConsultations,
          EnumPermissionsTypes.view,
        )}
      >
        <OnlineConsultations />
      </ScopeLayout>
    ),
  },

  //regions
  {
    path: "/regions",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.regions,
          EnumPermissionsTypes.view,
        )}
      >
        <RegionsList />
      </ScopeLayout>
    ),
  },
  {
    path: "/regions/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.regions,
          EnumPermissionsTypes.create,
        )}
      >
        <RegionCreate />
      </ScopeLayout>
    ),
  },
  {
    path: "/regions/edit/:code",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.regions,
          EnumPermissionsTypes.update,
        )}
      >
        <RegionEdit />
      </ScopeLayout>
    ),
  },

  //users
  {
    path: "/users",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.users,
          EnumPermissionsTypes.view,
        )}
      >
        <Users />
      </ScopeLayout>
    ),
  },
  {
    path: "/users/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.users,
          EnumPermissionsTypes.create,
        )}
      >
        <UserCreate />
      </ScopeLayout>
    ),
  },
  {
    path: "/users/edit/:id/:userId",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.users,
          EnumPermissionsTypes.update,
        )}
      >
        <UserUpdate />
      </ScopeLayout>
    ),
  },
  {
    path: "/users/details/:id/:userId",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.users,
          EnumPermissionsTypes.view,
        )}
        orPermissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.specialists,
          EnumPermissionsTypes.view,
        )}
      >
        <UserDetailsLayout>
          <UserInfo />
        </UserDetailsLayout>
      </ScopeLayout>
    ),
  },
  {
    path: "/users/details/:id/:userId/orders",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.orders,
          EnumPermissionsTypes.view,
        )}
      >
        <UserDetailsLayout>
          <UserOrders isByUser={true} />
        </UserDetailsLayout>
      </ScopeLayout>
    ),
  },
  {
    path: "/users/details/:id/:userId/my-referrals",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.referrals,
          EnumPermissionsTypes.view,
        )}
      >
        <UserDetailsLayout>
          <UserReferrals type={true} />
        </UserDetailsLayout>
      </ScopeLayout>
    ),
  },
  {
    path: "/users/details/:id/:userId/other-referrals",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.referrals,
          EnumPermissionsTypes.view,
        )}
      >
        <UserDetailsLayout>
          <UserReferrals type={false} />
        </UserDetailsLayout>
      </ScopeLayout>
    ),
  },
  {
    path: "/users/details/:id/:userId/doctor-details",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.users,
          EnumPermissionsTypes.view,
        )}
      >
        <UserDetailsLayout>
          <UserDoctorDetails />
        </UserDetailsLayout>
      </ScopeLayout>
    ),
  },
  {
    path: "/users/details/:id/:userId/nurse-orders",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.orders,
          EnumPermissionsTypes.view,
        )}
      >
        <UserDetailsLayout>
          <UserNurseOrders />
        </UserDetailsLayout>
      </ScopeLayout>
    ),
  },
  {
    path: "/users/details/:id/:userId/fees",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.users,
          EnumPermissionsTypes.special,
        )}
        orPermissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.specialists,
          EnumPermissionsTypes.view,
        )}
      >
        <UserDetailsLayout>
          <UserFees />
        </UserDetailsLayout>
      </ScopeLayout>
    ),
  },
  {
    path: "/users/details/:id/:userId/doctor-fees",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.users,
          EnumPermissionsTypes.special,
        )}
        orPermissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.specialists,
          EnumPermissionsTypes.view,
        )}
      >
        <UserDetailsLayout>
          <UserDoctorFee />
        </UserDetailsLayout>
      </ScopeLayout>
    ),
  },
  {
    path: "/users/details/:id/:userId/prices/:providerType/:countryCode/:provider/:name",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.prices,
          EnumPermissionsTypes.view,
        )}
      >
        <UserDetailsLayout>
          <ProviderPrices noContainer />
        </UserDetailsLayout>
      </ScopeLayout>
    ),
  },
  {
    path: "/users/details/:id/:userId/health-assessment",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.healthAssessment,
          EnumPermissionsTypes.view,
        )}
      >
        <UserDetailsLayout>
          <HealthAssessment />
        </UserDetailsLayout>
      </ScopeLayout>
    ),
  },

  //Applications
  {
    path: "/applications",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.applications,
          EnumPermissionsTypes.view,
        )}
      >
        <Applications />
      </ScopeLayout>
    ),
  },
  {
    path: "/applications/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.applications,
          EnumPermissionsTypes.view,
        )}
      >
        <ApplicationDetails />
      </ScopeLayout>
    ),
  },

  //Applications
  {
    path: "/clinicApplications",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.applications,
          EnumPermissionsTypes.view,
        )}
      >
        <ClinicApplications />
      </ScopeLayout>
    ),
  },

  //Dictionary Pages

  //Dictionary Pages - country params
  {
    path: "/countryParams",
    element: (
      <ScopeLayout
        isRequiredKey
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.countryParams,
          EnumPermissionsTypes.view,
        )}
      >
        <CountryParams />
      </ScopeLayout>
    ),
  },
  {
    path: "/countryParams/create",
    element: (
      <ScopeLayout
        isRequiredKey
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.countryParams,
          EnumPermissionsTypes.view,
        )}
      >
        <CountryParamsCreate />
      </ScopeLayout>
    ),
  },

  //Dictionary Pages - language
  {
    path: "/language",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.system,
          EnumPermissionsTypes.view,
        )}
      >
        <Language />
      </ScopeLayout>
    ),
  },
  {
    path: "/language/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.system,
          EnumPermissionsTypes.create,
        )}
      >
        <LanguageCreate />
      </ScopeLayout>
    ),
  },

  //Dictionary Pages - system message
  {
    path: "/systemMessage",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.system,
          EnumPermissionsTypes.view,
        )}
      >
        <SystemMessage />
      </ScopeLayout>
    ),
  },
  {
    path: "/systemMessage/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.system,
          EnumPermissionsTypes.create,
        )}
      >
        <SystemMessageCreate />
      </ScopeLayout>
    ),
  },

  //Dictionary Pages -
  {
    path: "/systemParam",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.system,
          EnumPermissionsTypes.view,
        )}
      >
        <SystemParam />
      </ScopeLayout>
    ),
  },
  {
    path: "/systemParam/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.system,
          EnumPermissionsTypes.create,
        )}
      >
        <SystemParamCreate />
      </ScopeLayout>
    ),
  },
  {
    path: "/systemParam/edit/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.system,
          EnumPermissionsTypes.update,
        )}
      >
        <DictionarySystemParamEdit />
      </ScopeLayout>
    ),
  },

  //Cities and Countries
  {
    path: "/countries",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.countries,
          EnumPermissionsTypes.view,
        )}
      >
        <Countries />
      </ScopeLayout>
    ),
  },
  {
    path: "/cities",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.cities,
          EnumPermissionsTypes.view,
        )}
      >
        <Cities />
      </ScopeLayout>
    ),
  },

  //Directions
  {
    path: "/directions",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.referrals,
          EnumPermissionsTypes.view,
        )}
      >
        <Referrals />
      </ScopeLayout>
    ),
  },

  //services
  {
    path: "/services",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.services,
          EnumPermissionsTypes.view,
        )}
      >
        <ServicesPage />
      </ScopeLayout>
    ),
  },
  {
    path: "/services/view/:id/:code",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.services,
          EnumPermissionsTypes.view,
        )}
      >
        <ServiceDetails />
      </ScopeLayout>
    ),
  },
  {
    path: "/services/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.services,
          EnumPermissionsTypes.create,
        )}
      >
        <CreateService />
      </ScopeLayout>
    ),
  },
  {
    path: "/services/types",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.services,
          EnumPermissionsTypes.view,
        )}
      >
        <ServiceTypesPage />
      </ScopeLayout>
    ),
  },
  {
    path: "/services/edit/:id/:code",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.services,
          EnumPermissionsTypes.update,
        )}
      >
        <EditService />
      </ScopeLayout>
    ),
  },

  //prices
  {
    path: "/prices",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.prices,
          EnumPermissionsTypes.view,
        )}
      >
        <NewPricesPage />
      </ScopeLayout>
    ),
  },
  {
    path: "/prices/:providerType/:countryCode/:provider/:name",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.prices,
          EnumPermissionsTypes.view,
        )}
      >
        <ProviderPrices />
      </ScopeLayout>
    ),
  },
  {
    path: "/prices/create/:countryCode/:provider/:providerType",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.prices,
          EnumPermissionsTypes.create,
        )}
      >
        <PricesCreate />
      </ScopeLayout>
    ),
  },
  {
    path: "/prices/edit/:countryCode/:provider/:providerType/:regionCode/:serviceCode",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.prices,
          EnumPermissionsTypes.update,
        )}
      >
        <PricesEdit />
      </ScopeLayout>
    ),
  },

  //campaigns
  {
    path: "/campaigns",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.campaigns,
          EnumPermissionsTypes.view,
        )}
      >
        <CampaignsPage />
      </ScopeLayout>
    ),
  },
  {
    path: "/campaigns/view/:campaignId",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.campaigns,
          EnumPermissionsTypes.view,
        )}
      >
        <CampaignsDetailsPage />
      </ScopeLayout>
    ),
  },
  {
    path: "/campaigns/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.campaigns,
          EnumPermissionsTypes.create,
        )}
      >
        <CreateCampaign />
      </ScopeLayout>
    ),
  },
  {
    path: "/campaigns/edit/:campaignId",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.campaigns,
          EnumPermissionsTypes.update,
        )}
      >
        <EditCampaign />
      </ScopeLayout>
    ),
  },

  //Clinics
  {
    path: "/clinics",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.clinics,
          EnumPermissionsTypes.view,
        )}
      >
        <Clinics />
      </ScopeLayout>
    ),
  },
  {
    path: "/clinics/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.clinics,
          EnumPermissionsTypes.create,
        )}
      >
        <ClinicsCreate />
      </ScopeLayout>
    ),
  },
  {
    path: "/clinics/edit/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.clinics,
          EnumPermissionsTypes.update,
        )}
      >
        <ClinicsEdit />
      </ScopeLayout>
    ),
  },
  {
    path: "/clinics/points/:id/:name",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.punkts,
          EnumPermissionsTypes.view,
        )}
      >
        <ClinicsPoints />
      </ScopeLayout>
    ),
  },
  {
    path: "/clinics/points/:id/:name/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.punkts,
          EnumPermissionsTypes.create,
        )}
      >
        <ClinicsPointsCreate />
      </ScopeLayout>
    ),
  },
  {
    path: "/branch/details/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.punkts,
          EnumPermissionsTypes.update,
        )}
      >
        <ClinicPointsDetails />
      </ScopeLayout>
    ),
  },

  //specializations
  {
    path: "/specializations",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.specializations,
          EnumPermissionsTypes.view,
        )}
      >
        <SpecializationsPage />
      </ScopeLayout>
    ),
  },
  {
    path: "/specializations/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.specializations,
          EnumPermissionsTypes.create,
        )}
      >
        <SpecializationsCreatePage />
      </ScopeLayout>
    ),
  },
  {
    path: "/specializations/edit/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.specializations,
          EnumPermissionsTypes.update,
        )}
      >
        <SpecializationsEditPage />
      </ScopeLayout>
    ),
  },

  //symptoms
  {
    path: "/symptoms",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.symptoms,
          EnumPermissionsTypes.view,
        )}
      >
        <SymptomsPage />
      </ScopeLayout>
    ),
  },
  {
    path: "/symptoms/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.symptoms,
          EnumPermissionsTypes.create,
        )}
      >
        <SymptomsCreatePage />
      </ScopeLayout>
    ),
  },
  {
    path: "/symptoms/edit/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.symptoms,
          EnumPermissionsTypes.update,
        )}
      >
        <SymptomsEditPage />
      </ScopeLayout>
    ),
  },

  //procedures
  {
    path: "/procedures",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.procedures,
          EnumPermissionsTypes.view,
        )}
      >
        <ProceduresPage />
      </ScopeLayout>
    ),
  },
  {
    path: "/procedures/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.procedures,
          EnumPermissionsTypes.create,
        )}
      >
        <CreateProcedures />
      </ScopeLayout>
    ),
  },
  {
    path: "/procedures/edit/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.procedures,
          EnumPermissionsTypes.update,
        )}
      >
        <EditProcedures />
      </ScopeLayout>
    ),
  },

  //mapping
  {
    path: "/mapping/service",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.mappings,
          EnumPermissionsTypes.view,
        )}
      >
        <MappingService />
      </ScopeLayout>
    ),
  },
  {
    path: "/mapping/service/edit/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.mappings,
          EnumPermissionsTypes.update,
        )}
      >
        <MappingServiceEdit />
      </ScopeLayout>
    ),
  },
  {
    path: "/mapping/service/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.mappings,
          EnumPermissionsTypes.create,
        )}
      >
        <MappingServiceCreate />
      </ScopeLayout>
    ),
  },
  {
    path: "/mapping/region",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.mappings,
          EnumPermissionsTypes.view,
        )}
      >
        <MappingByRegion />
      </ScopeLayout>
    ),
  },
  {
    path: "/mapping/region/edit/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.mappings,
          EnumPermissionsTypes.update,
        )}
      >
        <MappingRegionEdit />
      </ScopeLayout>
    ),
  },
  {
    path: "/mapping/region/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.mappings,
          EnumPermissionsTypes.create,
        )}
      >
        <MappingRegionCreate />
      </ScopeLayout>
    ),
  },

  //payments
  {
    path: "/payments",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.billing,
          EnumPermissionsTypes.view,
        )}
      >
        <Payments />
      </ScopeLayout>
    ),
  },

  //doctor fees
  {
    path: "/specialist-fees",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.specialistBilling,
          EnumPermissionsTypes.view,
        )}
      >
        <DoctorFees2 />
      </ScopeLayout>
    ),
  },
  {
    path: "/specialist-fees/accrue",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.specialistBilling,
          EnumPermissionsTypes.view,
        )}
      >
        <DoctorFees2Accrue />
      </ScopeLayout>
    ),
  },
  {
    path: "/specialist-fees/create",
    element: (
      <ScopeLayout
        isRequiredKey
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.specialistBilling,
          EnumPermissionsTypes.actions,
        )}
      >
        <DoctorFees2Create />
      </ScopeLayout>
    ),
  },
  // {
  //   path: "/additional-fees",
  //   element: (
  //     <ScopeLayout
  //       permissionKey={handleCreatePermissionCode(
  //         EnumPermissionsKeys.additionalBilling,
  //         EnumPermissionsTypes.view,
  //       )}
  //     >
  //       <AdditionalDoctorFees />
  //     </ScopeLayout>
  //   ),
  // },
  {
    path: "/additional-fees/history",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.additionalBilling,
          EnumPermissionsTypes.view,
        )}
      >
        <AdditionalDoctorFeesHistory />
      </ScopeLayout>
    ),
  },

  {
    path: "/grid-sales-region",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.regionGrids,
          EnumPermissionsTypes.view,
        )}
      >
        <GridSalesRegion />
      </ScopeLayout>
    ),
  },
  {
    path: "/grid-sales-region/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.regionGrids,
          EnumPermissionsTypes.create,
        )}
      >
        <GridSalesRegionCreate />
      </ScopeLayout>
    ),
  },
  {
    path: "/grid-sales-region/edit/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.regionGrids,
          EnumPermissionsTypes.update,
        )}
      >
        <GridSalesRegionEdit />
      </ScopeLayout>
    ),
  },

  {
    path: "/doctor-fees",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.doctorBilling,
          EnumPermissionsTypes.view,
        )}
      >
        <DoctorFees />
      </ScopeLayout>
    ),
  },
  {
    path: "/doctor-fees/accrue",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.doctorBilling,
          EnumPermissionsTypes.special,
        )}
      >
        <DoctorFeesAccrue />
      </ScopeLayout>
    ),
  },
  {
    path: "/doctor-fees/files",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.doctorBilling,
          EnumPermissionsTypes.special,
        )}
      >
        <DoctorFeesFiles />
      </ScopeLayout>
    ),
  },

  {
    path: "/content-statistics",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.contentStatistics,
          EnumPermissionsTypes.view,
        )}
      >
        <ContentStatistic />
      </ScopeLayout>
    ),
  },

  {
    path: "/vacancies",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.vacancies,
          EnumPermissionsTypes.view,
        )}
      >
        <Vacancies />
      </ScopeLayout>
    ),
  },

  {
    path: "/vacancies/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.vacancies,
          EnumPermissionsTypes.create,
        )}
      >
        <VacanciesCreate />
      </ScopeLayout>
    ),
  },

  {
    path: "/vacancies/edit/:id",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.vacancies,
          EnumPermissionsTypes.update,
        )}
      >
        <VacanciesEdit />
      </ScopeLayout>
    ),
  },

  {
    path: "/support/ticketTypes",
    element: (
      <ScopeLayout
        isRequiredKey
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.support,
          EnumPermissionsTypes.view,
        )}
      >
        <TicketTypes />
      </ScopeLayout>
    ),
  },
  {
    path: "/support/ticketTypes/create",
    element: (
      <ScopeLayout
        isRequiredKey
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.support,
          EnumPermissionsTypes.actions,
        )}
      >
        <TicketTypeForm />
      </ScopeLayout>
    ),
  },
  {
    path: "/support/ticketTypes/edit",
    element: (
      <ScopeLayout
        isRequiredKey
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.support,
          EnumPermissionsTypes.actions,
        )}
      >
        <TicketTypeForm />
      </ScopeLayout>
    ),
  },
  {
    path: "/support/tickets",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.support,
          EnumPermissionsTypes.view,
        )}
      >
        <TicketList />
      </ScopeLayout>
    ),
  },
  {
    path: "/support/tickets/view",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.support,
          EnumPermissionsTypes.view,
        )}
      >
        <TicketForm isViewOnly />
      </ScopeLayout>
    ),
  },
  {
    path: "/support/tickets/create",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.support,
          EnumPermissionsTypes.actions,
        )}
      >
        <TicketForm />
      </ScopeLayout>
    ),
  },
  {
    path: "/support/tickets/edit",
    element: (
      <ScopeLayout
        permissionKey={handleCreatePermissionCode(
          EnumPermissionsKeys.support,
          EnumPermissionsTypes.actions,
        )}
      >
        <TicketForm />
      </ScopeLayout>
    ),
  },
];
