import { Navigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { navigationsState } from "../store/settings.states";

const Redirect = () => {
  let location = useLocation();

  const navigation = useRecoilValue(navigationsState);

  const from =
    location.state?.from ||
    (navigation[0] && navigation[0].children
      ? navigation[0]?.children[0]?.path || ""
      : navigation[0]?.path) ||
    "/dashboard";

  return <Navigate to={from} />;
};

export default Redirect;
